<template>
  <div
    id="user-in-campaign-result"
    class="mb-5 d-flex flex-column"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.2)"
  >
    <div class="d-flex">
      <div
        class="d-flex align-items-start mt-3 flex-column"
        style="min-width: 280px"
      >
        <b-avatar v-if="user.profileImage" :src="user.profileImage" size="8rem">
        </b-avatar>
        <b-avatar v-else size="8rem"></b-avatar>

        <div>
          <h3 class="mt-4 mb-2">{{ user.nomeCompleto }}</h3>
        </div>
        <div v-if="user.progress" class="ml-auto" style="width: 15rem">
          <div class="d-flex justify-content-center">
            Total progress:
            <h4 class="ml-2">{{ user.progress }}%</h4>
          </div>
          <b-progress :value="user.progress"></b-progress>
        </div>
      </div>
      <div class="d-flex ml-5 mb-2 align-items-center flex-wrap">
        <div class="d-grid">
          <div class="mt-4">
            <h4>Awards</h4>
            <div class="d-flex flex-wrap">
              <div v-for="item in user.user_awards" :key="item.id">
                <b-avatar
                  :id="'popover-target-' + user.id_utente + '-' + item.id"
                  :src="item.url_image"
                  class="mb-3 mr-3"
                  :class="{ opacity: !item.vinto }"
                  size="4rem"
                >
                </b-avatar>
                <b-popover
                  :target="'popover-target-' + user.id_utente + '-' + item.id"
                  triggers="hover"
                  placement="top"
                >
                  {{ item.title["it-IT"] }}
                </b-popover>
              </div>

              <!-- <badge-award-widget
              v-for="award in user.user_awards"
              :key="award.id"
              :item="award"
            ></badge-award-widget> -->
            </div>
          </div>
          <div>
            <h4>Badges</h4>
            <div class="d-flex flex-wrap">
              <div :key="index" v-for="(badge, index) in user.user_badges">
                <b-avatar
                  :id="'popover-target-' + user.id_utente + '-' + badge.id"
                  :src="badge.url_image"
                  class="mb-3 mr-3"
                  :class="{ opacity: !badge.vinto }"
                  size="4rem"
                >
                </b-avatar>
                <b-popover
                  :target="'popover-target-' + user.id_utente + '-' + badge.id"
                  triggers="hover"
                  placement="top"
                >
                  {{ badge.title["it-IT"] }}
                </b-popover>
              </div>

              <!-- <badge-award-widget
              v-for="badge in user.user_badges"
              :key="badge.id"
              :item="badge"
            ></badge-award-widget> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showKpi" class="mt-4">
      <h4>Kpis</h4>
      <div class="grid-minmax">
        <kpi-widget
          v-for="item in user.user_kpis"
          :key="item.id"
          :item="item"
          :class="{ completed: item.perc_completamento >= 100 }"
        ></kpi-widget>
      </div>
    </div>
  </div>
</template>

<script>
import KpiWidget from "../components/KpiWidget.vue";
// import BadgeAwardWidget from "../components/BadgeAwardWidget.vue";
export default {
  name: "UserInCampaignResults",
  props: {
    user: Object,
    showKpi: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    KpiWidget,
    // BadgeAwardWidget,
  },
  created() {
    this.user.user_badges.forEach((element) => {
      element.text = element.title["it-IT"];
      element.image = element.url_image;
      element.status = element.vinto;
    });
    this.user.user_awards.forEach((element) => {
      element.text = element.title["it-IT"];
      element.image = element.url_image;
      element.status = element.vinto;
    });
  },
};
</script>

<style scoped>
/* * {
  border: 1px dotted red;
}

.emm {
  border: 2px solid green;
} */
</style>


