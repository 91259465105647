<template>
  <div class="content-wrapper">
    <h1>Campaign Results</h1>

    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <user-in-campaign-results
        v-for="user in computed_campaign_results"
        :key="user.id_utente"
        :user="user"
        :showKpi="!isOnboarding"
      ></user-in-campaign-results>
    </b-card>
  </div>
</template>

<script>
// import KpiWidget from "../components/KpiWidget.vue";
import UserInCampaignResults from "../components/UserInCampaignResults.vue";

import AxiosService from "./../axiosServices/AxiosService";

export default {
  components: {
    // KpiWidget,
    UserInCampaignResults,
  },
  service: null,
  data() {
    return {
      campaignId: null,
      filter: "",
      campaign_results: [],
      isOnboarding: false,
    };
  },
  created() {
    this.service = new AxiosService("User");

    this.service.readCustomEndpoint(
      "UserUtility/CheckupCampaignsForUsers/" + this.$route.params.campaignId
    );

    this.campaignId = this.$route.params.campaignId;

    this.loadUsers();
  },
  mounted() {
    this.service
      .readCustomEndpoint("Campaign/" + this.campaignId)
      .then((res) => {
        if (res.id_tipo_campagna == 1) {
          this.isOnboarding = true;
        }
      });
  },
  methods: {
    async loadUsers() {
      // const users = [];
      await this.service
        .readCustomEndpoint("CampaignManager/GetRanking/" + this.campaignId)
        .then((res) => {
          res.forEach((element) => {
            let tempUser = element;
            tempUser.user_kpis = [];
            tempUser.user_badges = [];
            tempUser.user_awards = [];

            //Load KPIS
            this.service
              .readCustomEndpoint(
                "CampaignManager/GetUserKpiById/" +
                  this.campaignId +
                  "/" +
                  element.id_utente
              )
              .then((data) => {
                data.forEach((element) => {
                  element.text = element.friendlyName["it-IT"];
                  element.point = element.valore;
                  element.target = element.goal;
                  tempUser.user_kpis.push(element);
                });
              });

            //Load Badges
            this.service
              .readCustomEndpoint(
                "CampaignManager/GetUserBadges/" +
                  this.campaignId +
                  "/" +
                  element.id_utente
              )
              .then((data) => {
                data.forEach((element) => {
                  tempUser.user_badges.push(element);
                });
              });

            //Load Awards
            this.service
              .readCustomEndpoint(
                "CampaignManager/GetUserAward/" +
                  this.campaignId +
                  "/" +
                  element.id_utente
              )
              .then((data) => {
                data.forEach((element) => {
                  tempUser.user_awards.push(element);
                });
              });
            tempUser.nomeCompleto = tempUser.nome + " " + tempUser.cognome;
            this.campaign_results.push(tempUser);
          });
        });

      console.log(this.campaign_results);
    },
  },
  computed: {
    computed_campaign_results() {
      let array = [];
      let filter = this.filter;

      this.campaign_results.forEach((element) => {
        if (element.nomeCompleto.toLowerCase().includes(filter.toLowerCase())) {
          array.push(element);
        }
      });
      return array;
    },
  },
};
</script>